.page-section{
}


.grid-section {
    background-size:cover;
    background-position: center;
    transition: 0.3s all;
}

.grid-description {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 100px;
    background: rgba(0, 0, 0, 0.95);
    padding-right: 100px;
}

.grid-description:hover{
    background: rgba(0, 0, 0, 0.8);
    transition: 0.3s all;

}

.grid-heading{
    padding: 0px 10px 10px 10px;
    font-family: 'Myriad Pro Regular' sans-serif;
    color: #fff;
    font-size: 20px;
    -webkit-background-clip: text;
    font-weight: 500;
}

.grid-subtitle {
    padding: 0px 10px 10px 10px;
    color: #ededed;
    font-size: 13px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {


.grid-description {
    text-align: center;
    padding: 50px 50px 50px 50px;
    background: rgba(0, 0, 0, 0.95);
}

}