.slider{
    background-size: cover;
    height: 100vh;
    z-index: -1;
    overflow-y: auto;
    color: #fff;
    -ms-flex-align: center!important;
    align-items: center!important;
    transition: .4s all;
}

.align-items-center {
    display: flex; 
    height: 100vh;
    align-items: center;  /*Aligns vertically center */
    justify-content: center; /*Aligns horizontally center */
  }

.slider-title{
    font-size: 50px;
    text-shadow: 0.5px 0.5px rgba(0, 0, 0, 0.3);
    font-family: 'Myriad Pro Regular';
    letter-spacing: 3px;
} 

.slider-subtitle {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 1px;
}

.slider-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    color: #fff;
    width: 200px;
    margin: 20px;
    border: none;
    padding:12px;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 2px;
    text-transform: uppercase;
}

.slider-button:hover {
    background: #fff !important;
    color: #383838 !important;
}

.slider-button:active {
    background: #fff !important;
    color: #383838 !important;
}

.whatsapp-button {
    background: rgba(109,213,104, 0.2);
    width: 200px;
    padding:12px;
    margin: 20px 5px 20px 5px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 2px;
    border: none;
    transition: .4s all;
}

.whatsapp-button-docked {
    
    z-index: 9;
    
}

.whatsapp-button:hover {
    background: rgba(109,213,104, 1);
}

@keyframes kenburns {
    0% {
     transform: scale3d(1, 1, 1);
    animation-timing-function: ease-out;
      opacity: 1;
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
        transform: scale3d(1.1, 1.1, 1.1);
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .slider-title {
        font-size: 25px;
    }

    .slider-subtitle {
        text-align: center;
        font-size: 15px;
    }
   
  }