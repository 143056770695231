.summer-page-wrapper{
    background: #000;
    overflow: hidden;
}

.height100{
    height: 100vh;
}

.summer-page-header-container{
    padding-top: 100px;
    text-align: center;
    height: 100vh;
    background: url("https://firebasestorage.googleapis.com/v0/b/thelabs-917af.appspot.com/o/A2.png?alt=media&token=c6cade09-7751-47ae-978a-5a497f37e2ec");
    background-size: cover;
    background-position: center;
    padding-bottom: 100px;
}

.summer-header {
    font-size: 80px;
    padding-top: 150px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}

.summer-mobile-header {
    font-size: 80px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}

.summer-subtitle {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}


.summer-toptitle {
    font-size: 10px;
    color: #fff;
    font-weight:400;
    line-height: 1;
    letter-spacing: 1px;
}

.summer-section-heading{
    font-size: 30px;
    padding-top: 100px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}

.p-100{
    padding: 100px;
}

.img-card-container {
    overflow: hidden;
    margin-right: -10px;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #1e1e1e;
    transition: 1s all;
}

.img-card-container:hover{
    transform: scaleX(1.2) scaleY(1.2);
    transition: 1s all;
}

.flexible-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.summer-text-rows {
}


.highlight-text-red {
    background: -webkit-linear-gradient(#FF512F, #DD2476);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlight-text-no {
    background: -webkit-linear-gradient(45deg, #12c2e9 ,#c471ed ,#f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlight-text {
    background: -webkit-linear-gradient(180deg, #FFEFBA, #FFEFBA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlight-text-no {
    background: -webkit-linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.summer-text-rows {
    display:flex;
}

.summer-text-rows li {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    text-decoration: none;
    list-style: none;
}

.overflowHidden {
    overflow: hidden;
}

#pinContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  #pinContainer .panel {
    height: 100vh;
    width: 100vw;
    position: absolute;
    text-align: center;
  }

  
  .panel.black {
    background-color: #000000;
  }

  .align-items-center {
    display: flex; 
    height: 100vh;
    align-items: center;  /*Aligns vertically center */
    justify-content: center; /*Aligns horizontally center */
  }

  .paris-portrait{
    background: url("https://res.cloudinary.com/labs-international/image/upload/v1573933977/Summer%202017/Summer%2020%20Page/ParisPortrait_i1b75y.png");
    background-size: cover;
    min-height: 600px;
    color: #fff;
    background-position: center;
    transition: 0.3s all;
    opacity: 0.9;
  }

  .paris-portrait:hover{
      opacity: 1;
      transition: 0.3s all;
  }

  .brussels-portrait{
    background: url("https://res.cloudinary.com/labs-international/image/upload/v1573933987/Summer%202017/Summer%2020%20Page/BrusselsPortrait_dfy1dz.png");
    background-size: cover;
    min-height: 600px;
    color: #fff;
    background-position: center;
    transition: 0.3s all;
    opacity: 0.9;
  }

  .brussels-portrait:hover{
    opacity: 1;
    transition: 0.3s all;
}


.big-section-heading{
    font-size: 70px;
    padding-top: 100px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}


.highlight-paragraph{
    font-size: 50px;
    padding-top: 100px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}

  .geneva-portrait{
    background: url("https://res.cloudinary.com/labs-international/image/upload/v1573933972/Summer%202017/Summer%2020%20Page/GenevaPortrait_nvuyhq.png");
    background-size: cover;
    min-height: 600px;
    color: #fff;
    background-position: center;
    transition: 0.3s all;
    opacity: 0.9;
  }

  .geneva-portrait:hover{
    opacity: 1;
    transition: 0.3s all;
}

.portrait-text {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    letter-spacing: 1px;
}

.subject-text {
    font-size: 15px;
    padding-right: 50px;
    color: #fff;
    letter-spacing: 1px;
    transition: 0.3s all;
}

.monalisa-img {
    background-image: url("https://res.cloudinary.com/labs-international/image/upload/v1573933978/Summer%202017/Summer%2020%20Page/monalisa_wpo8vs.png");
    background-size: cover;
    background-position: center;
    min-height: 900px;
}

.p-up-down-100{
    padding: 100px 0px 100px 0;
}

.summer-bar{
    display: none;
    overflow-y: auto;
    bottom:0;
    z-index: 100;
    position: fixed;
    opacity: 0.5;
    transition: 0.3s all;
}

.summer-bar:hover {
    opacity: 1;
    transition: 0.3s all;
}

.summer-bar-inner{
    background: #000;
    padding: 10px;
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
}

.summer-bar-inner li{
    text-decoration: none;
    list-style: none;
    font-size: 11px;
    margin: 0px 10px 0px 10px;
    color: #fefefe;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.summer-bar-inner #program-title{
    font-size: 11px;
    text-align: left;
    text-transform: none;
    font-weight: bold;
}

.whatsapp-button-docked {
    z-index: 9;
    position: fixed;
    right: 5%;
    bottom: 2%;
    background: rgba(109,213,104, 0.8);
    padding:10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 8px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 2px;
    border: none;
    transition: .4s all;
}

.underline {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.99);
    min-height: 10px;
}

.program-detail-heading{
    font-size: 20px;
    padding-top: 50px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
}

.program-detail-container{
    background: #000;
    color: #000 !important;
}


  /* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {

    .mobile-wrapper{
        padding-left:40px;
        padding-right: 40px;
    }

    .summer-page-header-container{
        padding-top: 100px;
        text-align: center;
        height: 70vh;
        background-size: cover;
        background-position: center;
        padding-bottom: 100px;
    }
    
    .summer-mobile-header {
        font-size: 30px;
        text-align: left;
        line-height: 1.2;
        letter-spacing: 1px;
    }

    .summer-header {
        font-size: 40px;
        padding-top: 100px;
        color: #fff;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1px;
    }

    .highlight-text {
        background: -webkit-linear-gradient(180deg, #FFEFBA, #FFEFBA);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .img-card-container {
       display: none;
    }

    .portrait-container{
        padding: 20px;
    }
    
    .paris-portrait {
        margin-bottom: 20px;
    }

    .brussels-portrait {
        margin-bottom: 20px;
    }

    .geneva-portrait {
        margin-bottom: 20px;
    }


    .subject-text {
        padding-right: 0px;
        font-size: 13px;
    }   
    
    .monalisa-img {
        min-height: 500px;
    }

    .big-section-heading{
        font-size: 30px;
    }

    .highlight-paragraph{
        font-size: 20px;
    }

    .p-100{
        padding: 75px;
    }

    .hide-on-mobile {
        display: none;
    }
    .whatsapp-button-docked {
        display: block;
    }

}