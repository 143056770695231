.learning-wrapper {
    height: 100vh;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-items: center;
    background-size: cover;
}


.wls-heading {
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bolder;
    text-shadow: 1px 1px 10px #000;
    padding: 20px 20px 0px 20px;
}

.wls-description {
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    text-shadow: 1px 1px 10px #000;
    padding: 0px 20px 20px 20px;

}