.subject-section-header {
    font-family: 'Roboto' sans-serif;
    font-weight: 500;
    color: #000;
    padding: 100px 100px 50px 100px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.subject-title {
    font-family: 'Roboto' sans-serif;
    font-weight: 500;
    color: #000;
    letter-spacing: 1px;
    font-size: 26px;
    padding: 75px 0px 15px 0px;
}

.subject-description {
    text-align: justify;
    font-size: 13px;

}

.subjectbox{
    text-align: center !important;
    transition: 0.5s all;
}

.subjectbox:hover{
    background: #fff;
    box-shadow: 0px 0px 50px #ededed;
    transition: 0.5s all;
    text-align: center !important;
    border-radius: 10px;
}

.subject-wrapper {
    padding-bottom: 100px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {

    .subject-section-header {
        font-family: 'Roboto' sans-serif;
        color: #000;
        padding: 10px 100px 10px 100px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1.5px;
    }

    .subjectbox {

        padding-left: 50px;
        padding-right: 50px;
    }

}