.boarding-pass-container{
    background-size: cover;
    padding: 50px;
}

.boarding-pass-inner {
    background: #fff;
    box-shadow: 1px 1px 10px #ededed;
    border-radius: 2px;
}

.boarding-top-row {
    background: #000;
    border-radius: 2px 2px 0 0;
}

.boarding-top-row p {
    color: #fff;
    padding: 15px;
    font-size: 15px;
    font-weight: 500;
}

.align-items-center {
    display: flex; 
    height: 100vh;
    align-items: center;  /*Aligns vertically center */
    justify-content: center; /*Aligns horizontally center */
  }

  .subtitle{
      margin: 15px;
      text-transform: uppercase;
      font-size: 10px;
      color: #adadad;
  }

  .heading {
    margin: 15px;
    font-size: 13px;
    color: #000;
  }

  .last-section {
      border-right: 1px dotted #adadad;
  }

  .learn-more {
      font-size: 10px;
      margin: -18px 15px 15px 15px;
      color: #1976d2;
  }

  .big-heading {
    margin-top: -10px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 25px;
    color: #000;
  }


.boarding-bottom-row {
    background: #ebebeb;
    border-radius: 0 0 2px 2px;
}

.bottom-row-title {
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    color: #adadad;
}

.bottom-row-subtitle {
    font-size: 12px;
    margin-top: -15px !important;
    font-weight: bold;
    text-transform: uppercase;
    color: #adadad;
}

.apply-button {
    background:#000;
    border-radius: 5px;
    color: #fff;
    width: 200px;
    margin: 15px;
    border: none;
    padding:12px;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;

    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.apply-button:hover {
    background: #000 !important;
    color: #fff !important;
}

.apply-button:active {
    background: #000 !important;
    color: #fff !important;
}



/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .section-video {
        width: 100vw;
        text-align: center;
     }

     .hide-in-mobile{
         display: none;
     }

     .boarding-pass-container{
        padding: 30px;
    }
  }