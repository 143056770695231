.wn-wrapper{
    background: #0c0c0c;
    color: #fff;
}

.wn-inner {
    display: flex;
    flex-direction: row;
    align-content: center;
    vertical-align: middle;
    justify-content: flex-start;

}

.wn-inner li{
    list-style: none;
    letter-spacing: 1px;
    font-size: 13px;
    padding: 10px 20px 10px 20px;
}

.wn-inner li#company{
    list-style: none;
    letter-spacing: 1px;
    font-size: 13px;
    padding: 10px 20px 10px 20px;
}

.onlyMobile{
    display: none;
}

.mobileHeader{
    background: #0c0c0c;
}

.wn-wrapper a,.wn-wrapper a:hover,.wn-wrapper a:focus{
    text-decoration: none;
    color: #fff;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .onlyDesktop {
        display: none;
    }

    .onlyMobile{
        display: block;
    }

    .wn-inner-mobile {
        display: flex;
        flex-direction: row;
        align-content: center;
        vertical-align: middle;
        justify-content: flex-start;
        padding: 10px;
    }

    .wn-inner-mobile li{
        list-style: none;
        letter-spacing: 1px;
        font-size: 13px;
        padding: 10px 20px 10px 20px;
    }

    .wn-inner-mobile li#company{
        list-style: none;
        letter-spacing: 1px;
        font-size: 20px;
        padding: 0px 20px 0px 20px;
    }
  }