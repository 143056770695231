.header{
    position: absolute;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 1px;
    z-index: 9;
}

a { color: inherit; } 

a:hover {
    color: inherit;
    text-decoration: none;
}

.topbar{
    padding: 30px;
    font-size: 11px;
    color: #fff;
}

.testpos {
    background: #000000;   
}

.logo{
    padding: 10px;
    font-size: 20px;
    color: #fff;
}

.mainarea {
    padding: 5px;

}
.navarea {
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.99);
}

.navpills {
    float: left;
    padding-bottom: 25px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    opacity: 0.7;
    transition: 0.5s all;
}

.navpills-active{
    float: left;
    padding-bottom: 25px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    opacity: 1;
    transition: 0.5s all;
}

.navpills:hover {
    opacity: 1;
    transition: 0.5s all;
}

.navpills-subtext {
    float: left;
    font-size: 11px;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .topbar {
        display: none;
    }

    .navarea {
        text-align: center;
        border-bottom: 0.1px solid rgba(255, 255, 255, 0.99);
    }

    .navpills-subtext {
        float: none;
        font-size: 11px;
    }
  }