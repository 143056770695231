.testimonial-section-wrapper{
    display: flex;
    align-items: center;
    justify-items: center;
}

.testimonial-container {
    padding: 100px 50px 100px 50px ;
}

.testimonial-heading-container {
    padding: 60px;
    align-items: center;
    justify-items: center;
    height: 100%;
}

.testimonial-section-heading {
    font-family: 'Roboto' sans-serif;
    font-weight: 400;
    font-size: 35px;
}

.testimonial-section-subtitle {
    font-size: 15px;

}

.testimonial-grid-container {
    padding: 5px;
    cursor: pointer;
}

.testimonial-grid-university{
    font-size: 12px;
}

.testimonial-grid {
   border-radius: 2px;
   height: 310px;
   width: 310px;
   background-size: cover;
   background-position: center;
   transition: 0.4s all;
   box-shadow: 0px 0px 2px #424242;

}

.testimonial-grid:hover {
    box-shadow: 0px 0px 10px #424242;
    transition: 0.4s all;
}

.testimonial-grid-subtitle {
    text-align: center;
    padding: 20px;
    font-weight: 600;
    font-size: 15px;
}

.city {
    color: #1976d2;
}

.chennai {
    color: #f9c933;
}

.modal-header-image {
    width: auto;
    background-size: cover;
    background-position: center;
    height: 400px;
}

.modal-heading {
    font-size: 25px;
    text-align: center;
    padding: 50px 50px 0px 50px;
    font-weight: 600;
}

.modal-subtitle{
    font-size: 12px;
    text-align: center;
    padding: 0px 10px 10px 10px;
}

.testimonial {
    padding: 0px 20px 0px 20px;
    font-size: 14px;
    text-align: justify;
}

.close-text {
    font-size: 14px;
    color: #FF2000;
    cursor: pointer;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {

    
}