.registration-section-header {
    font-family: 'Roboto' sans-serif;
    font-weight: 500;
    color: #000;
    padding: 100px 100px 50px 100px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.inclusion-unit {
   transition: 0.3s all;
   padding: 20px;
}

.inclusion-unit:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 20px #ededed;
    padding: 20px;
    transition: 0.3s all;
}

.inclusion-icon {
    width: 60px;
    margin: 10px;
}

.inclusion-title {
    padding: 10px;
    color: #000;
    letter-spacing: 0.5px;
    font-size: 13px;
    text-align: center;
}

.register-background {
    background: #000;
    margin-top: 100px;
    padding: 100px;
}

.register-title {
    font-size: 15px;
    padding: 20px 20px 10px 20px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    font-style: bold;
}

.register-amount {
    font-size: 25px;
    padding: 0px 20px 0px 20px;
    color: #fff;
    text-align: center;
    margin-top: -15px;
    font-style: bold;
}

.register-subtitle {
    font-size: 13px;
    color: #fff;
    font-style: italic;
    text-align: center;
    margin-top: -15px;
    letter-spacing: 1px;
    padding: 0px 20px 0px 20px;
}

.discount-label {
    background: #54a650;
    padding: 5px;
    color: #fff;
    text-align: center;
    font-size: 8px;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 2px;
}

.register-button {
    padding: 10px 20px 10px 20px;
    border: none;
    border-radius: 2px;
    font-size: 10px;
    letter-spacing: 0.5px;
    outline: none;
    transition: 0.3s all;

}

.register-button:hover {
    border-radius: 0px;
    transition: 0.3s all;
}



.registration-unit .discount-label {
    background: #E0AA3E;
    color: #fff;
}


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {

    .register-title {
        font-size: 15px;
        padding: 0;
        color: #fff;
        text-align: left;
        letter-spacing: 1px;
        font-weight: bold;
    }
    
    .register-amount {
        font-size: 25px;
        padding: 0px 0px 0px 0px;
        color: #fff;
        text-align: left;
        margin-top: 0px;
        font-style: bold;
    }

    .register-subtitle {
        font-size: 12px;
        color: #fff;
        font-style: italic;
        text-align: left;
        letter-spacing: 1px;
        padding: 0px;
    }

    .discount-label {
        background: #54a650;
        padding: 6px;
        color: #fff;
        text-align: center;
        font-size: 10px;
        margin-left: 0px;
        margin-right: 70px;
        border-radius: 10px;
    }

    .button-container {
        width: 100%;
        text-align: left;
    }

    .register-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    color: #fff;
    border: none;
    padding:12px;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: 0.2s all;
    margin-bottom: 50px;

    transition: 0.3s all;
    }

    .register-button:hover {
        background: #fff !important;
        color: #383838 !important;
        transition: 0.3s all;

    }
}