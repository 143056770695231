/*
About Page
*/

.about-container {
  background: #000000;
  padding-top: 100px;
}

.about-header {
  font-size: 60px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #fff;
  padding: 100px 50px 50px 50px;
}

.about-title {
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  color: #d3d3d3;
}

.about-page-header-container {
  padding-top: 100px;
  text-align: center;
  height: 80vh;
  background: url('https://images.unsplash.com/photo-1519526528934-2d2213986004?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80');
  background-size: cover;
  background-position: center;
  padding-bottom: 100px;
}

.adwait-portrait {
  background: url('https://media-exp1.licdn.com/dms/image/C5103AQG6Zbu4KUIYSQ/profile-displayphoto-shrink_800_800/0/1579089752979?e=1616630400&v=beta&t=6ZSaVImli2aJCsdVE_LmMdhd9GyyS6M6kvUTl7FWMA0');
  background-size: cover;
  height: 500px;
  color: #fff;
  background-position: center;
  transition: 0.3s all;
  opacity: 0.9;
}

.tanishq-portrait {
  background: url('https://pbs.twimg.com/profile_images/1350814166383210498/wMyfDmC5_400x400.jpg');
  background-size: cover;
  height: 500px;
  color: #fff;
  background-position: center;
  transition: 0.3s all;
  opacity: 0.9;
}

.divyang-portrait {
  background: url('https://media-exp1.licdn.com/dms/image/C5103AQFIVjk4M23fyQ/profile-displayphoto-shrink_800_800/0/1581648076580?e=1616630400&v=beta&t=WAu9OuZnCMP7b2HEaw8-wgrh_9duoelbdapF_QWsVm8');
  background-size: cover;
  height: 500px;
  color: #fff;
  background-position: center;
  transition: 0.3s all;
  opacity: 0.9;
}

.shlok-portrait {
  background: url('https://media-exp1.licdn.com/dms/image/C4E03AQHf2ROs1_nFxg/profile-displayphoto-shrink_800_800/0/1610563449430?e=1616630400&v=beta&t=WA_u7lg6n9hd6eFo3arBW0lX28RIXukhq5n0-bwsvNw');
  background-size: cover;
  height: 500px;
  color: #fff;
  background-position: center;
  transition: 0.3s all;
  opacity: 0.9;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .about-header {
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #fff;
    padding: 50px;
  }
}
