/*
Mobile Menu
 */

 .mobile-menu {
    height: 0%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    background-color:#0c0c0c; /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.no-scroll-window {
    overflow: hidden !important;
}

.mobile-menu-container {
    padding: 10px 20px 10px 20px;
    color: #ffffff;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);

}

.mobile-menu-title {
    list-style: none;
    letter-spacing: 1px;
    font-size: 15px; 
    padding: 10px 20px 10px 20px;
}

.menu-close{
    font-weight: 100;
    font-size: 32px;
    color: #ffffff;
    cursor: pointer;
}

.main-pill-container {
    margin: 18px;
    padding: 5px;
}

.main-pill-container li{
    font-weight: 500;
    color: #ededed;
    font-size: 18px;
    cursor: pointer;
    letter-spacing: 1px;
    list-style-type: none;
    transition: 0.2s all;
    padding: 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);

}

.mobile-menu-main-pill:hover {
    font-size: 27px;
    transition: 0.2s all;
}

.underline {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.99);
    min-height: 10px;
}

.socialIcons{
    padding: 10px;
}

.mobile-footer{
    font-size: 15px;
    color: #fff;
    letter-spacing: 2px;
}

.mobile-menu a,
.mobile-menu a:hover,
.mobile-menu a:focus{
    text-decoration: none;
    color: #fff;
}