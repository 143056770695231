.inner-nav {
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.top-nav-container {
    background: #0c0c0c;
}

.inner-nav li {
    text-decoration: none;
    list-style: none;
    font-size: 10px;
    margin: 0px 10px 0px 10px;
    color: #fefefe;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.inner-nav li:hover {
    font-size: 10.5px;
    transition: 0.2s all;
}

.new-label {
    color: #d32f2f;
}

.success-label {
    color: #52da37;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .inner-nav {
        text-align: center !important;
        justify-content: center;
    }
  }