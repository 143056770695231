.grid-item { width: 200px; }
.grid-item--width2 { width: 400px; }

  .gallery-header {
      font-size: 60px;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      color: #fff;
      padding: 100px 50px 50px 50px;
  }

  .gallery-title {
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    color: #d3d3d3;
  }

  .gallery-container {
      background: #000;
      padding-top: 100px;
  }

  .gallery-container img {
    
  }

  .gallery-container img:hover {
   
  }

.timeline {
  border-right: 1px solid #fcfcfc;
}

.timeline-header {
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
}

  /* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .gallery-header {
        font-size: 30px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color: #fff;
        padding: 50px;
    }
}