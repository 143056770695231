.footer-container {
    background: #000;
    height: auto;
    padding: 100px;
}

.footer-container {
    padding-top: 100px;
   
}

.footer-header {
    color: #fcfcfc;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
}

.footer-content {
    padding: 10px 0px 0px 0px;
    text-align: justify;
    color: #fcfcfc;
    font-size: 12px;
}

.footer-content li {
    text-decoration: none;
    list-style-type: none;
}

.footer-menu-header {
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

.footer-menu-content {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
}

.copyright-text {
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 0px 15px 0px 15px;
    font-weight: 500;
}

.break{
    border-top: 1px solid #fcfcfc;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .footer-container {
        background: #000;
        height: auto;
        padding: 20px 20px 100px 20px;
    }
  }