
.winter-why {
    padding: 100px;
    height: 100vh;
}

.section-video {
   width: 100vw;
   height: 100vh;
   position: absolute;
   text-align: center;

}

.height-full {
    height: 100vh;
}

.hideVideo {
    display:none !important;
}

.overlay {
    padding: 10px;
    background: rgba(0, 0, 0, 0.95);
}

.no-space {
    margin: 0;
    padding: 0;
}

.why-container {
    padding: 15px;
}

.why-grid{
    color: #efefef;
    padding: 50px 50px 50px 50px;
    transition: 0.3s all;
}

.why-grid:hover{
   color: #fff !important;
   background-size: contain;
   cursor: pointer;

}

.why-header{
    margin: 0;
    font-family: 'Myriad Pro Regular';
    text-align: center;
    font-size: 22px;
    
}

.why-subtitle {
    margin: 0;
    font-size: 12px;
    text-align: center;
}

.why-section-heading{
    color: #fff;
    font-size: 30px;
    padding-top: 30px;
    font-weight: bold;
}


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .section-video {
        width: 100vw;
        text-align: center;
        display: none;
     }

     .height-full {
        height: 100%;
    }
    .winter-why {
        height: 100%;
    }
  }