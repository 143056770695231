.faqs-heading {
    padding: 50px 25px 0px 25px;
    font-size: 1.8em;
    font-family: 'Myriad Pro Regular' sans-serif;
    color: #0a0a0a;
    font-weight: bold;
}

.faqs-description {
    padding: 0px 25px 25px 25px;
    margin-top: -15px;
    color: #383838;
    font-size: 0.8em;
}

.faqs-subheading {
    font-size: 0.8em;
    font-weight: bold;
    color: #0a0a0a;
    text-transform: uppercase;

}

.faqs-container .whatsapp-button-light {
    z-index: 9;
    bottom: 0;
    background: rgba(109,213,104, 0.8);
    width: 250px;
    padding:12px;
    margin: 20px 20px 20px 0px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Myriad Pro Regular';
    letter-spacing: 2px;
    border: none;
    transition: .4s all;
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .faqs-heading {
        text-align: center;
    }
    
    .faqs-description {
        text-align: center;
    }

    .faqs-subheading {
        text-align: center;
    }

    .faqs-container .whatsapp-button-light {
        text-align: center !important;
    }
}